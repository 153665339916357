module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LCWVJFCD2L"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Matanah B'Sesser - South Florida's Pillar of Support","short_name":"Matanah B'Sesser","start_url":"/","background_color":"#ffffff","theme_color":"#fed136","display":"minimal-ui","icon":"content/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dcba95d979472bdbf3fd21e6ebd14e3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Montserrat","file":"https://fonts.googleapis.com/css?family=Montserrat&display=swap"},{"name":"Droid+Serif","file":"https://fonts.googleapis.com/css?family=Droid+Serif&display=swap"},{"name":"Days+One","file":"https://fonts.googleapis.com/css?family=Days+One&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css?family=Roboto&display=swap"},{"name":"Montserrat","file":"https://fonts.googleapis.com/css?family=Montserrat&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
